export const services = [
    // {
    //   title: "Shipping",
    //   tagline: "From all orders",
    //   image: "images/service.png",
    // },
    
    {
      title: "Support 24/7",
      tagline: "Shop with an expert",
      image: "images/service-03.png",
    },
    {
      title: "Affordable Prices",
      tagline: "Get Factory Default Price",
      image: "images/service-04.png",
    },
    {
      title: "Cash on Delievery",
      tagline: "100% Protected Payment",
      image: "images/service-05.png",
    },
  ];