import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import { BiPhoneCall, BiInfoCircle } from "react-icons/bi";
import Container from "../components/Container";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { createQuery } from "../features/contact/contactSlice";

const contactSchema = yup.object({
  name:yup.string().required("Name is Required"),
  email:yup.string().nullable().email("Email should be valid").required("Email is Requied"),
  mobile:yup.string().default('').nullable().required("Mobile Number is Required"),
  comment:yup.string().default('').nullable().required("Comment is Required")
})
const Contact = () => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues:{
      name:"",
      email:"",
      mobile:"",
      comment:"",

    },
    validationSchema:contactSchema,
    onSubmit: values =>{
      // alert(JSON.stringify(values,null,2));
      dispatch(createQuery(values))
    }
  })
  return (
    <>
      <Meta title={"Contact Us"} />
      <BreadCrumb title="Contact Us" />
      <Container class1="contact-wrapper py-5 home-wrapper-2">
        <div className="row">
          
          <div className="col-12 mt-5">
            <div className="contact-inner-wrapper d-flex justify-content-between ">
              <div>
                <h3 className="contact-title mb-4">Contact</h3>
                <form action="" onSubmit={formik.handleSubmit} className="d-flex flex-column gap-15">
                  <div>
                    <input
                      type="text"
                      className="form-control .contact-input"
                      placeholder="Name"
                      name ="name"
                      onChange={formik.handleChange("name")}
                      onBlur={formik.handleBlur('name')}
                      value={formik.values.name}
                    />
                    <div className="errors">{
                      formik.touched.name && formik.errors.name
                    }
                    </div>
                  </div>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name ="email"
                      onChange={formik.handleChange("email")}
                      onBlur={formik.handleBlur('email')}
                      value={formik.values.email}
                    />
                    <div className="errors">{
                      formik.touched.email && formik.errors.email
                    }
                    </div>
                  </div>
                  <div>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Mobile Number"
                      name ="mobile"
                      onChange={formik.handleChange("mobile")}
                      onBlur={formik.handleBlur('mobile')}
                      value={formik.values.mobile}
                    />
                    <div className="errors">{
                      formik.touched.mobile && formik.errors.mobile
                    }
                    </div>
                  </div>
                  <div>
                    <textarea
                      
                      id=""
                      className="w-100 form-control"
                      cols="30"
                      rows="4"
                      placeholder="Comments"
                      name ="comment"
                      onChange={formik.handleChange("comment")}
                      onBlur={formik.handleBlur('comment')}
                      value={formik.values.comment}
                    ></textarea>
                    <div className="errors">{
                      formik.touched.comment && formik.errors.comment
                    }
                    </div>
                  </div>
                  <div>
                    <button className="button border-0">Submit</button>
                  </div>
                </form>
              </div>
              <div>
                <h3 className="contact-title mb-4">Get in touch with us</h3>
                <div>
                  <ul className="ps-0">
                    <li className="mb-3 d-flex gap-15 align-items-center">
                      <AiOutlineHome className=" .contact-icon fs-7 " />
                      <address className="mb-0 contact-text">
                        Kathmandu, Nepal
                      </address>
                    </li>
                    <li className="mb-3 d-flex gap-15 align-items-center">
                      <BiPhoneCall className="fs-7 .contact-icon" />
                      <a className="contact-text" href="tel:+977 984-9651409">+977 984-9651409</a>
                    </li>
                    <li className="mb-3 d-flex gap-15 align-items-center">
                      <AiOutlineMail className="fs-7 .contact-icon"/>
                      <a href="mailto:vyam@gmail.com" className="contact-text contact-text-site">
                        vyam.bdago8@gmail.com
                      </a>
                    </li>
                    <li className="mb-3 d-flex gap-15 align-items-center">
                      <BiInfoCircle className="fs-7 .contact-icon" />
                      <p className="mb-0 contact-text-open">Monday – Friday 10 AM – 8 PM</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Contact;
